import React from "react"
import { getInitials, stringToHslColor } from '../utils/Functions'
//import ReactTooltip from 'react-tooltip'
import './GetInitials.scss'

class Status extends React.Component {
  render() {
    const { name } = this.props
    if (name) {
      return (
        <span className="member">
          <span className="initials" style={{background: stringToHslColor(name)}} title={name}>
            {getInitials(name)}
          </span>
        </span>
      )
    }else{
      return null
    }
  }
}

export default Status
