import React from "react"
import Layout from "../components/Layout"
import { Router } from "@reach/router"
import UxReview from "../components/UxReview"

const Index = () => (
  <Layout>
    <Router>
      <UxReview path="/uxreview/:splat" />
    </Router>
  </Layout>
)

export default Index
